import {Component, Input, OnInit} from '@angular/core';
import { Validators, FormGroup, FormBuilder} from '@angular/forms';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {environment} from "../../../environments/environment";
import { AuthenticationService } from '../../services';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  hidePassword = true;
  loginForm: FormGroup;
  loading = false;
  errorMessage = "";
  forgotPassword = false;
  maintenance= "normal";

  @Input()
  set maintenanceMode(mode: string) {
    this.maintenance = mode;
  }
  get maintenanceMode(): string {
    return this.maintenance;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    ) { }



  ngOnInit(): void {
    this.loginForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      }
    );
  }

  get fields() { return this.loginForm.controls; }

  onSubmit(shouldSubmit) {
    if(shouldSubmit){

      // stop here if form is invalid
      if (this.loginForm.invalid) {
        return;
      }

      // redirect to employee login if internal email
      if(this.fields.email.value.includes("@macrohelix.com") || this.fields.email.value.includes("@mckesson.com")){
        this.employeeLogin();
      }
      else{
        this.loading = true;
        this.authenticationService.login(this.fields.email.value, this.fields.password.value)
          .pipe(first())
          .subscribe(
            data => {
              window.location.href = environment.appUri + '?token=' + data.token;
            },
            error => {
              this.errorMessage = error.error.error || error.statusText;
              this.loading = false;
            }
          );
      }
    }
  }

  getParameterByName(name) {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  forgotPwdGetEmail(){
    return this.fields.email.valid ? this.fields.email.value : "";
  }

  employeeLogin(){
    window.location.href = environment.ssoLoginUri;
  }

}
