import {VERSION} from "./version";

export const environment = {
  production: true,
  apiUri: 'https://api.mhiapps.com',
  appUri: 'https://web.mhiapps.com',
  appVersion: VERSION.version+"."+VERSION.raw+"-prod",
  loginUri: 'https://www.mhiapps.com',
  ssoLoginUri: 'https://mckesson.okta.com/app/mckessoncorporationproduction_340barchitect_1/exk69628higCxyOip357/sso/saml',//'https://fedsvc.mckesson.com/adfs/ls/',
  apiKey: "7b08b12da617447a83ed05a9e0482595",
  UAMUserApiEndpoint: "/user",
  b2c: {
    RedirectUrl: "https://web.mhiapps.com/",
  }
};
