import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenResponse } from '../models/token-response';
import { environment } from "../../environments/environment";
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  private getHeaders(){
    return new HttpHeaders({
      'Ocp-Apim-Subscription-Key': environment.apiKey,
      'Content-Type': 'application/json'
    });
  }

  login(email: string, password: string) {
    return this.http.post<any>(environment.apiUri + environment.UAMUserApiEndpoint + '/v1/login/authenticate', { email, password }, {headers: this.getHeaders()})
        .pipe(map((token: TokenResponse) => token));
  }

  resetpassword(email: string){
    return this.http.post<any>(environment.apiUri + environment.UAMUserApiEndpoint + '/v1/login/resetpassword', { email }, {headers: this.getHeaders()})
        .pipe(map((res: Response) => res));
  }

  getMainteance(){
    return this.http.get(environment.apiUri + environment.UAMUserApiEndpoint + '/v1/maintenance', {headers: this.getHeaders(), responseType: 'text'})
      .pipe(map((res: any) => res ));
  }

  checkTwitterConnection(){
    return from(
      fetch('https://twitter.com', {headers: {'Content-Type': 'application/json'}, method: 'GET', mode: 'no-cors'})
      );
  }
}
