import { Component } from '@angular/core';
import { environment } from "../environments/environment";
import { AuthenticationService } from "./services";
import { first } from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LoginUI';
  isIE;
  twitterBlocked: boolean = false;
  year: number;

  public version: string;
  public maintenance: string;
  public todayDate: Date = new Date();

  constructor(private authenticationService: AuthenticationService) {
    this.version = environment.appVersion;
  }

  ngOnInit() {
    //Check for Internet Explorer browser
    this.isIE = /*@cc_on!@*/false || !!document['documentMode'];
    this.year = new Date().getFullYear();
    if (!this.isIE){
      //check for twitter being blocked
      this.authenticationService.checkTwitterConnection()
        .pipe(first())
        .subscribe(
          resp => this.twitterBlocked = false,
          error => this.twitterBlocked = true
        );
    }

    this.authenticationService.getMainteance()
      .pipe(first())
      .subscribe(
        data => {
          this.maintenance = data;
        },
        error => {
          this.maintenance = "unknown";
        });
  }

  login(){
     window.location.href =  environment.b2c.RedirectUrl;
  }

  checkContentExpiration(startDate, endDate){
    return this.todayDate < new Date(endDate) && this.todayDate >= new Date(startDate);
  }
}
