import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService } from '../../services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  @Input() forgotEmail: string;
  
  forgotPasswordForm: FormGroup;
  errorMessage = "";
  submitMessage = "";
  submitted = false;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService,) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group(
      {
        forgotEmail: [this.forgotEmail, [Validators.required, Validators.email]]
      }
    );
  }

  onSubmit(){
    this.authenticationService.resetpassword(this.forgotPasswordForm.controls.forgotEmail.value)
      .pipe(first())
      .subscribe(
        data => {
          this.submitted = true;
          this.submitMessage = "If " + this.forgotPasswordForm.controls.forgotEmail.value + " is associated with a 340B Architect ID, you should receive an email with a link to reset your password.";
        },
        error => {
          //console.log(error);
          this.errorMessage = error.error.error || error.statusText;
        });
  }

}
