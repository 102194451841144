<div class="ui-app">
  <mat-sidenav-container>
      <mat-sidenav #drawer class="login-sidenav" mode="over" position="end">
        <button class="close-nav-button" (click)="drawer.toggle()" type="button">&#10005;</button>
        <login-form [maintenanceMode]="maintenance"></login-form>
      </mat-sidenav>
      <mat-sidenav-content>
          <!--Header-->
          <div class="ui-header">
              <div class="ui-sub-header">
                  <div class="ui-header-logo">
                      <a href="http://www.macrohelix.com/" title="Macro Helix" target="_blank">
                          <img id="mh-logo" src="/assets/img/MacroHelix-White.png" alt="Macro Helix" />
                      </a>
                      <div class="column-follow-button">
                        <div class="sub-column-follow-button">
                          <a *ngIf="!isIE && !twitterBlocked" href="https://twitter.com/macrohelix?ref_src=twsrc%5etfw"
                              class="twitter-follow-button" data-size="large" data-show-screen-name="false"
                              data-show-count="false">follow
                              @macrohelix
                          </a>
                          <button onclick="window.location.href='//www.linkedin.com/company/macro-helix-inc.'"
                          class="linkedin-follow-button" data-size="large" data-show-screen-name="false"
                          data-show-count="false">
                            <img  class = "linkedinStyle" src="../assets/img/LinkedIn-Button-Normal-01 (1).svg"/>
                          </button>
                        </div>
                      </div>
                  </div>
                  <div class="ui-header-labels">
                  </div>
              </div>
          </div>
          
          <!--End Header-->

          <!--Body-->
          <div class="ui-body">
              <div class="ui-sub-body">
                <!--Left-->
                <div class="ui-body-left">
                  <div *ngIf="isIE || twitterBlocked; then thenBodyLeft else elseBodyLeft"></div>
                  <ng-template #thenBodyLeft>
                    <div *ngIf="isIE; else blockedTwitter" class="body-left-placeholder">
                      <div style="font-size: 14px; font-weight: bold; margin-top: 16px;">For Internet Explorer Users</div>
                      <div style="font-size: 12px;">Macro Helix has updated our site to provide more dynamic content relevant to our customers.
                        Unfortunately, some functionality is unavailable for customers using the Internet Explorer (IE)
                        browser. Customers who have the option to use an alternate browser such as Microsoft Edge,
                        Google Chrome, or Firefox will have an improved experience.</div>
                    </div>
                  </ng-template>
                  <ng-template #blockedTwitter>
                    <div class="body-left-placeholder">
                      <div style="font-size: 14px; font-weight: bold; margin-top: 16px;">Hmmm … Did you miss a dose?</div>
                      <div style="font-size: 12px;">Macro Helix has updated our site to provide more dynamic content to our customers. 
                        Unfortunately, some functionality is unavailable for customers whose corporate firewalls block social media feeds. 
                        We’re sorry you can’t see that content here, but we hope you’ll follow us on Twitter and LinkedIn to view helpful 
                        information and announcements- No prescription needed. 
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #elseBodyLeft>
                    <div style="height: 85%;">
                      <!--overflow: scroll;-->
                      <a class="twitter-timeline" href="https://twitter.com/macrohelix?ref_src=twsrc%5etfw">
                      </a>
                      <script async src="https://platform.twitter.com/widgets.js" charset="utf-8">
                      </script>
                        <a class="linkedin-timeline" href="https://www.linkedin.com/company/macro-helix-inc.">
                        </a>
                      <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
                      <script type="macrohelix?ref_src" data-id="1337" data-counter="bottom"></script>
                      
                    </div>
                  </ng-template>
                </div>
                <!--End Left-->

                <!--Middle-->
                <div class="ui-body-middle">
                  <div *ngIf="checkContentExpiration('02/02/2023','02/18/2023')">
                    <a href=" https://forms.office.com/pages/responsepage.aspx?id=G-9n2lnKsk2ajKqNlGF6Fh_mv7iiaiBBrdty46YGmfNURENFWk85V1FVTEwyVFo2VVdVOEo0S0NLQy4u" target="_blank">
                      <img src="/assets/img/PriceRestorationSurvey.png" alt="Manufacturer Restriction Feedback" style="width: 480px"/>
                    </a>
                    <p class="other-content">
                     <span class="other-content-title">
                      Manufacturer Restriction Feedback <br/>
                     </span>
                     What can we do to help you manage manufacturer restrictions? Please take this <a href=" https://forms.office.com/pages/responsepage.aspx?id=G-9n2lnKsk2ajKqNlGF6Fh_mv7iiaiBBrdty46YGmfNURENFWk85V1FVTEwyVFo2VVdVOEo0S0NLQy4u" target="_blank"><b>survey</b></a> by February 17th and let us know.
                    </p>
                  </div>
                  <div>
                    <img src="/assets/img/PriceDetectionBulkExclusions2.png" alt="Price Restoration" style="width:480px" />
                    <p class="other-content">
                      <span class="other-content-title">
                          Now Available: Price Detection/Bulk Exclusions Tool
                      </span>
                      Check out the new price detection feature in 340B Architect! This tool allows you to review when a change in 340B pricing is identified for your pharmacies. Now you can act quickly and accurately to ensure maximum 340B savings. Learn more in the Help Guide.
                    </p>
                  </div>
                  <div>
                    <div class="ui-content-block1">
                      <img src="/assets/img/Impact Login 7.png" alt="340BImpact" />
                      <div class="content-block-video1">
                        <iframe width="90%" height="125px" src="https://player.vimeo.com/video/697023238?h=681942d02e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="340BImpact_Lookbook_Tag.mp4"></iframe>
                      </div>
                      <a href="https://engage.mckesson.com/340bimpact-lookbook/p/1" target="_blank" class="mat-stroked-button1">Download the Lookbook</a>
                      <p class="other-content">
                        <span class="other-content-title">340B Impact&#8480; for Covered Entities</span><br>
                        Our newest solution, 340B Impact, is an interactive business intelligence solution and a value-added solution for joint McKesson and Macro Helix customers. Want to know more? <a href="https://macrohelix.com/" target="_blank">Learn more</a>
                      </p>
                    </div>
                  </div>
                  <div class="other-content">
                    <img src="/assets/img/Got_Answers 1.png" alt="Answers">
                    <p>
                      <span class="other-content-title">Tackling Your Top Questions</span><br>
                      We’re tackling your top support questions and taking you straight to the solution. From retail status changes to prescriber lists, <span style="font-weight: bold;">search "<span style="font-style: italic;">Hot Topics</span>"</span> in the Help Guide to save time!
                    </p>
                  </div>
                </div>
                  <!--End Middle-->
                  <!--Right-->
                  <div class="ui-body-right">
                    <div class="ui-body-right-content">
                      340B Architect
                      <div *ngIf="maintenance!='employee' && maintenance!='total'">
                        <button mat-button (click)="login()" type="button">Login</button>
                      </div>
                      <div *ngIf="maintenance=='total' || maintenance=='employee'">
                        <h2>Maintenance Mode</h2>
                        340B Architect is currently unavailable.
                        We apologize for any inconvenience.
                      </div>
                    </div>
                    <div class="ui-body-right-bottom">
                      Customers may contact us at <a href="mailto:support@macrohelix.com">support@macrohelix.com</a><br> or call (888) 462-4526.
                      <br><br>
                      <span class="textMHBlue">&#169;{{this.year}} Macro Helix LLC. <br>All rights reserved.</span>
                    </div>
                  </div>
                  <!--End Right-->
                </div>
            </div>
            <!--End Body-->

          <!-- Footer -->
          <div class="ui-footer">
            <div class="ui-footer-links">
              <a href="https://macrohelix.com/" target="_blank">Home</a>
              <a href="https://macrohelix.com/terms-of-use/" target="_blank">Terms of Use</a>
              <a href="https://www.mckesson.com/saas/340b-architect/" target="_blank">Privacy Policy</a>
              <a href="https://macrohelix.com/contact-us/" target="_blank">Contact Us</a>
              <a href="https://macrohelix.com/about-us/" target="_blank">About Us</a>
            </div>
          </div>
          <!-- End Footer -->
      </mat-sidenav-content>
  </mat-sidenav-container>
<div style="display: none" >Version: {{version}}</div>
</div>
