<div *ngIf="maintenance!='employee'&&maintenance!='total'">
  <div class="sidenav-info">
    <h1>340B Architect</h1>
    <h2>Secure Client Login</h2>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit(true)" class="login-form" *ngIf="!forgotPassword" (keydown)="onSubmit($event.keyCode == 13)">
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput placeholder="pat@example.com" formControlName="email" required>
    </mat-form-field>
    <mat-form-field class="login-password" appearance="fill">
      <mat-label>Password</mat-label>
      <input formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'">
      <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <a mat-button class="forgot-password" (click)="forgotPassword = !forgotPassword"> Reset your password </a>
    <button [disabled]="loading" class="login-button" mat-stroked-button type="submit">
      <mat-spinner class="loading-spinner" *ngIf="loading" [diameter]="25"></mat-spinner>
      <span *ngIf="!loading">Login</span>
    </button>
    <div class="error-message" [innerHtml]="errorMessage"></div> <!--{{errorMessage}}-->
    <hr>
    <a mat-button class="employee-login" (click)="employeeLogin()">Macro Helix Employee Login</a>
  </form>
  <div *ngIf="forgotPassword">
      <button mat-stroked-button (click)="forgotPassword = !forgotPassword">&#8592;&nbsp;Back</button>
      <reset-password [forgotEmail]="forgotPwdGetEmail()"></reset-password>
  </div>
</div>
<div *ngIf="maintenance=='employee'">
  <div class="sidenav-info">
    <h1>340B Architect</h1>
    <h2>Maintenance Mode</h2>
  </div>
  340B Architect is currently unavailable.
  We apologize for any inconvenience.
  <hr>
  <a mat-button class="employee-login" (click)="employeeLogin()">Macro Helix Employee Login</a>
</div>
<div *ngIf="maintenance=='total'">
  <div class="sidenav-info">
    <h1>340B Architect</h1>
    <h2>Maintenance Mode</h2>
  </div>
  340B Architect is currently unavailable.
  We apologize for any inconvenience.
</div>
